import React, { Fragment } from 'react';
import './HomePage.css';

const HomePage = () => {
    return (
        <Fragment>
            <div id = "main">
                <div className={'wrapper'}>
                    <div className='name'>
                        <span>cristian</span>
                    </div>

                    <div className='description'>
                        Software engineer with 11 years of experience crafting<br/>
                        exceptional user experiences and architecting scalable systems.
                    </div>

                    <div className='content'>
                        <div className='content-section'>
                            <div className='content-section-title'>code / work</div>
                            <div className='content-section-block'>
                                <div><a target="_blank" rel="noopener noreferrer"
                                        href="https://github.com/RusuTraianCristian">GitHub</a></div>
                                <div><a target="_blank" rel="noopener noreferrer"
                                        href="https://s3.us-west-2.amazonaws.com/rusutraiancristian.com/SER.pdf">resume</a>
                                </div>
                            </div>
                        </div>
                        <div className='content-section'>
                            <div className='content-section-title'>career / founder</div>
                            <div className='content-section-block'>
                                <div><a target="_blank" rel="noopener noreferrer" href="https://telluric.ai">founder
                                    Telluric AI</a></div>
                            </div>
                        </div>
                        <div className='content-section'>
                            <div className='content-section-title'>social</div>
                            <div className='content-section-block'>
                                <div><a target="_blank" rel="noopener noreferrer"
                                        href="https://www.linkedin.com/in/rusutraiancristian/">LinkedIn</a></div>
                                <div><a target="_blank" rel="noopener noreferrer"
                                        href="https://twitter.com/RusuTCristian">X</a></div>
                            </div>
                        </div>
                        <div className='content-section'>
                            <div className='content-section-title'>email</div>
                            <div className='content-section-block'>
                                <div><a target="_blank" rel="noopener noreferrer"
                                        href="mailto:rusutraiancristian@gmail.com">rusutraiancristian@gmail.com</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='description'>
                        <span>Essays</span>
                        <span>
                            <a
                               href="/understanding-market-dynamics">Understanding Market Dynamics</a>
                        </span>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default HomePage;